@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  @apply text-blue-600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* styles.css */
.scrollbar-hide {
  scrollbar-width: thin;
  /* For Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  width: 0;
  /* For Safari and Chrome */
}

.scrollbar-hide:hover::-webkit-scrollbar {
  width: 8px;
  /* For Safari and Chrome */
}

.ProseMirror:focus {
  outline: none;
}

/* Add this to your global CSS file or create a new CSS module */

.prose table {
  @apply w-full border-collapse bg-white text-sm shadow-md rounded-lg overflow-hidden;
}

.prose thead {
  @apply bg-gray-50;
}

.prose th {
  @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
}

.prose tbody tr {
  @apply border-b border-gray-200;
}

.prose tbody tr:last-child {
  @apply border-b-0;
}

.prose td {
  @apply px-6 py-4 whitespace-nowrap;
}

/* Add some hover effect to table rows */
.prose tbody tr:hover {
  @apply bg-gray-50;
}

/* Style for resizer */
.tableWrapper .resizer {
  @apply absolute right-0 top-0 h-full w-1 bg-blue-500 opacity-0 transition-opacity cursor-col-resize;
}

.tableWrapper .resizer:hover,
.tableWrapper .resizer.isResizing {
  @apply opacity-100;
}

/* Ensure the editor content is responsive */
.prose {
  @apply max-w-full;
}

.tooltip {
  --tooltip-color: #000;
  --tooltip-text-color: #FFF;
}

.tiptap p.is-editor-empty:first-child::before,
.tiptap h1.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

/* Webkit browsers (Chrome, Safari, newer versions of Edge) */
::-webkit-scrollbar {
  width: 0;
}

/* Firefox */
* {
  scrollbar-width: none;
}

/* For Internet Explorer and Edge (legacy versions) */
body {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

/* iframe#webpack-dev-server-client-overlay{display:none!important} */
