.rhap_container {
  font-family:
  'Inter',
  -apple-system,
  BlinkMacSystemFont,
  source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  width: 100%;
  padding: 0;
  box-shadow: none;
}

.rhap_container.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.rhap_progress-bar {
  background-color: rgb(51 51 51 / 1);
}

.rhap_progress-filled {
  background-color: rgb(7 104 253 / 1);
}

.rhap_progress-bar-show-download {
  background-color: rgb(229 231 235 / 1);
}

.rhap_progress-indicator {
  background: rgb(7 104 253 / 1);
  box-shadow: none;
}

.rhap_stacked .rhap_controls-section {
  margin-top: 20px;
  margin-bottom: 20px;
}

.rhap_time {
  width: 5ch;
  overflow: hidden;
}

.modal-box {
  transition: box-shadow 0.25s ease-in-out;
}

.modal-box.recording {
  box-shadow: 0 0 50px 5px rgba(255, 0, 0, 0.50);
}

.modal-box.paused {
  box-shadow: 0 0 50px 5px rgba(33 150 243 /.5);
}

.rhap_repeat-button {
  pointer-events: none;
  opacity: 0;
}

.rhap_main-controls-button {
  color: rgb(51 51 51 / 1);
}

.rhap_play-pause-button {
  font-size: 70px;
  width: 70px;
  height: 70px;
}

.rhap_volume-button {
  color: rgb(51 51 51 / 1);
  margin-right: 8px;
}

.rhap_volume-bar {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 4px;
  background: rgb(229 231 235 / 1);
  border-radius: 2px;
}

.rhap_volume-indicator {
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 12px;
  margin-left: -6px;
  left: 0;
  top: -4px;
  background: rgb(51 51 51 / 1);
  opacity: 0.9;
  border-radius: 50px;
  box-shadow: none;
  cursor: pointer;
}

.rhap_volume-indicator:hover {
  opacity: 0.9;
}

.rhap_volume-filled {
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: rgb(51 51 51 / 1);
  border-radius: 2px;
}

/* Utils */
.rhap_button-clear {
  background-color: transparent;
  border: none;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
}

.rhap_button-clear:hover {
  opacity: 0.9;
  transition-duration: 0.2s;
}

.rhap_button-clear:active {
  opacity: 0.95;
}

.rhap_button-clear:focus:not(:focus-visible) {
  outline: 0;
}

.rhap_time {
  color: rgb(51 51 51 / 1);
  font-weight: 500;
}